import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TableSortLabel,
  Checkbox,
  TextField,
  makeStyles,
} from "@material-ui/core";
import { DateTime } from "luxon";

const useStyles = makeStyles({
  tableCell: {
    // padding: "0.5rem",
    fontSize: '11px',
  },
  tableHeadCell: {
    padding: "1.5rem",
    fontSize: '11px',
    textTransform: 'uppercase',
  },
});

const CustomTable = ({ columns, data, onSelectAll, onUpdate, hideCheckbox, selected, setSelected }) => {
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(columns[0]?.id || "");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filters, setFilters] = useState({});

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleFilterChange = (column, value) => {
    setFilters({ ...filters, [column]: value });
  };

  const getNestedValue = (obj, path) => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  };

  const filteredData = data.filter((row) =>
    Object.keys(filters).every((key) => {
      const value = getNestedValue(row, key);
      return !filters[key] || (value && String(value).toLowerCase().includes(filters[key].toLowerCase()));
    })
  );


  const sortedData = [...filteredData].sort((a, b) => {
    const valueA = a[orderBy];
    const valueB = b[orderBy];
    // Encontre a definição da coluna para verificar se é do tipo "date"
    const column = columns.find(col => col.id === orderBy);

    // Verifica se a coluna é do tipo "date"
    if (column && column.type === "date") {
      const dateA = DateTime.fromFormat(valueA, 'dd/MM/yyyy HH:mm:ss').toMillis();
      const dateB = DateTime.fromFormat(valueB, 'dd/MM/yyyy HH:mm:ss').toMillis();
      return order === "asc" ? (dateA > dateB ? 1 : -1) : (dateB > dateA ? 1 : -1);
    }

    // Caso contrário, compara diretamente os valores
    return order === "asc" ? (valueA > valueB ? 1 : -1) : valueA < valueB ? 1 : -1;
  });

  const handleChangePage = (_, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const allIds = data.map((row) => row._id); // Pega todos os IDs
      setSelected(allIds);
    } else {
      setSelected([]);
    }
  };

  const handleClick = (id) => {
    if(hideCheckbox) return
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) newSelected = [...selected, id];
    else newSelected = selected.filter((s) => s !== id);

    setSelected(newSelected);
  };

  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {
              hideCheckbox != true && (
                <TableCell padding="checkbox" align={'center'}>
                  <Checkbox
                    indeterminate={selected?.length > 0 && selected?.length < data?.length}
                    checked={data?.length > 0 && selected?.length === data?.length}
                    onChange={handleSelectAllClick}
                  />
                </TableCell>

              )
            }
            {columns.map((column) => (
              <TableCell key={column.id} className={classes.tableHeadCell} align={'left'}>
                <TableSortLabel
                  active={orderBy === column.id}
                  direction={orderBy === column.id ? order : "asc"}
                  onClick={() => handleRequestSort(column.id)}
                >
                  {column.label}
                </TableSortLabel>
                {column.filterable && (
                  <TextField
                    placeholder={column.label}
                    align={'center'}
                    onChange={(e) => handleFilterChange(column.id, e.target.value)}
                    InputProps={{
                      style: { textAlign: "left" }, // Alinha a direita o texto digitado
                    }}
                    inputProps={{
                      style: { textAlign: "left" }, // Alinha a direita o placeholder
                    }}
                    fullWidth
                  />
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
            <TableRow key={row._id} hover onClick={() => handleClick(row._id)} role="checkbox" selected={selected.includes(row._id)}>
              {
                hideCheckbox != true && (
                  <TableCell padding="checkbox" align={'center'}>
                    <Checkbox checked={selected.includes(row._id)} />
                  </TableCell>
                )
              }

              {columns.map((column) => (
                <TableCell key={column.id} className={classes.tableCell} align={column.id == 'status' ? 'center' : 'left'}>
                  {column.render ? column.render(row) : row[column.id]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={sortedData?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
};

export default CustomTable;

import styled from 'styled-components';
import React, { useState } from 'react';
import Loading from '../../Components/Loading/index.jsx';
import { Grid, Row, Col, Badge, ControlLabel } from 'react-bootstrap';
import MyAPI from '../../../../connections/Api.jsx';
import { StatsCard } from '../../../../components/StatsCard/StatsCard.jsx';
import * as yup from 'yup';
import Settings from './Settings.jsx';
import CustomTable from '../../../../components/CustomTable/CustomTable.jsx';
import DenseTable from '../../../../components/CustomTable/DenseTable.jsx';
import { Button, Card, FormGroup, Input, Popover, Typography } from '@material-ui/core';
import { FilterList } from '@material-ui/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import DatePicker from "react-16-bootstrap-date-picker";
import { useRef } from 'react';
import moment from 'moment';
import { DateTime } from 'luxon';


export const Content = styled.div`
  cursor: pointer;
`;

const StyledDatePickerWrapper = styled.div`
  .popover-content {
    display: none;
  }
  .popover-title {
    display: none;
  }
`;

const schema = yup.object().shape({
  filtroDataInicio: yup
    .string()
    .nullable()
    .test("is-date", "Data inválida", (value) => {
      if (!value) return true; // Permite valores nulos
      const date = DateTime.fromISO(value);
      return date.isValid || value.length < 10; // Ignora valores incompletos (menos de 'YYYY-MM-DD')
    }),
  filtroDataFim: yup
    .string()
    .nullable()
    .test("is-date", "Data inválida", (value) => {
      if (!value) return true;
      const date = DateTime.fromISO(value);
      return date.isValid || value.length < 10;
    }),
});

export default function DashboardPix() {
  const [data, setData] = useState(null);
  const [selected, setSelected] = useState([]);
  const [blockedTransactions, setBlockedTransactions] = useState(null);
  const [pixPayments, setPixPayments] = useState(null);
  const [dateFilter, setDateFilter] = useState({});
  const [refresh, setRefresh] = React.useState(false);
  const [page, setPage] = React.useState('Notifications');
  const [anchorEl, setAnchorEl] = useState(null);
  const calendarRef = useRef(null);
  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      filtroDataInicio: DateTime.now().toISODate(),
      filtroDataFim: DateTime.now().toISODate(),
    }
  });

  const filtrarDados = (data) => {
    if (!pixPayments?.data) return;

    const dataInicio = data.filtroDataInicio
      ? DateTime.fromISO(data.filtroDataInicio).startOf("day").toMillis()
      : null;

    const dataFim = data.filtroDataFim
      ? DateTime.fromISO(data.filtroDataFim).endOf("day").toMillis()
      : null;

    let filtrados = pixPayments.data.filter((transacao) => {
      const dataTransacao = DateTime.fromFormat(transacao.created_at, 'dd/MM/yyyy HH:mm:ss').toMillis();
      return (!dataInicio || dataTransacao >= dataInicio) &&
        (!dataFim || dataTransacao <= dataFim);
    });

    setPixPayments(obj => ({ ...obj, data: filtrados }));
  };



  const columns = [
    {
      id: 'status', label: 'Status', filterable: true, render: (row) => {
        if (row.status == "Pago") {
          return <Badge
            variant="dot"
            style={{ backgroundColor: '#198754', fontSize: '11px' }}
          >{row.status}</Badge>
        }
        if (row.status == "Não Processada") {
          return <Badge
            variant="dot"
            style={{ backgroundColor: '#ffc107', fontSize: '11px' }}
          >{row.status}</Badge>
        }
        if (row.status == "Bloqueada") {
          return <Badge
            variant="dot"
            style={{ backgroundColor: '#dc3545', fontSize: '11px' }}
          >{row.status}</Badge>
        }
      }
    },
    { id: 'amount', label: 'Valor', filterable: true, type: 'string', render: (row) => formatCurrency(row.amount) },
    { id: 'usuario.nome', label: 'Funcionário', filterable: true, type: 'string', render: (row) => row.usuario?.nome || '---' },
    // { id: 'usuario.nome', label: 'Funcionário', filterable: true, render: (row) => row.usuario?.nome || '---' },
    { id: 'created_at', label: 'Data', filterable: true, type: 'date', },
    { id: 'pix_code', label: 'Chave Pix', filterable: true, type: 'string', },
    { id: 'credenciado.nome_fantasia', label: 'Credenciado', filterable: true, type: 'string', render: (row) => row.credenciado?.nome_fantasia || '---' },
  ];

  const formatCurrency = (value) => `R$ ${value.toFixed(2).replace('.', ',')}`;

  const handleChangeDateRangePicker = ({startDate, endDate}) => {
    setDateFilter({startDate, endDate});
  }

  const roundToTwo = (num) => Math.round(num * 100) / 100;

  React.useEffect(() => {
    async function fetchData() {
      try {
        let queryData = "";
        if(dateFilter) {
          queryData = {
            startDate: dateFilter.startDate,
            endDate: dateFilter.endDate,
          };
        }
        const { data } = await MyAPI.getPixPayments(queryData);
        const blocked = await MyAPI.getBlockedPixPayments(queryData);
        let numberOfTransactionsWithSuccess = 0;
        let numberOfTransactionsWithError = 0;
        data.map((item) => {
          if (item.status == "Pago") numberOfTransactionsWithSuccess++
          if (item.status == "Não Processada") numberOfTransactionsWithError++

        })
        let blockedsTransactions = []
        blocked.data.map(item => {
          blockedsTransactions.push({
            status: "Bloqueada",
            amount: item.amount,
            usuario: item.usuario,
            type: item.type,
            created_at: item.blocked_at,
            pix_code: item.pix_code,
            credenciado: null
          })
        })
        console.log([...data, ...blockedsTransactions])
        setPixPayments({ data: [...data, ...blockedsTransactions], numberOfTransactionsWithSuccess, numberOfTransactionsWithError });
        setBlockedTransactions(blocked.data)

      } catch (e) {
        console.log(e);
      }
    }
    fetchData();
  }, [refresh]);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  if (pixPayments === null) return <Loading Height='950px' />;

  return (
    <div className='content'>
      <Grid fluid>
        <Row>
          <Col lg={4}>
              <StatsCard
                bigIcon={
                  <i
                    className='pe-7s-paper-plane'
                    style={{ color: 'rgba(0,0,0,.7)' }}
                  />
                }
                statsText='Pix realizados'
              statsValue={pixPayments?.numberOfTransactionsWithSuccess || 0}
                statsIcon={<i className='fa fa-info-circle' />}
                statsIconText='Total de transações realizadas'
              />
          </Col>
          <Col lg={4}>
              <StatsCard
                isSelected={page === 'Ramicash'}
                bigIcon={
                  <i
                    className='pe-7s-cash'
                    style={{ color: 'rgba(0,0,0,.7)' }}
                  />
                }
                statsText='Número de solicitações'
              statsValue={pixPayments?.data?.length || 0}
                statsIcon={<i className='fa fa-info-circle' />}
              statsIconText={`Total de ${pixPayments?.data?.length || 0} solicitações encontradas`}
              />
          </Col>
          <Col lg={4}>
            <StatsCard
              bigIcon={
                <i
                  className='pe-7s-users'
                  style={{ color: 'rgba(0,0,0,.7)' }}
                />
              }
              statsText='Usuários ativos'
              statsValue={pixPayments?.data?.allUsers || 0}
              statsIcon={<i className='fa fa-info-circle' />}
              statsIconText='Usuários que realizaram transações pix'
            />
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
              <StatsCard
                bigIcon={
                  <i
                    className='pe-7s-paper-plane'
                    style={{ color: 'rgba(0,0,0,.7)' }}
                  />
                }
                statsText='Tentativas sucessivas'
              statsValue={0}
                statsIcon={<i className='fa fa-info-circle' />}
                statsIconText='Número de tentativas sucessivas'
              />
          </Col>
          <Col lg={4}>
            <StatsCard
              bigIcon={
                <i
                  className='pe-7s-attention'
                  style={{ color: 'rgba(0,0,0,.7)' }}
                />
              }
              statsText='Transações com erro'
              statsValue={pixPayments?.numberOfTransactionsWithError || 0}
              statsIcon={<i className='fa fa-info-circle' />}
              statsIconText='Transações não processadas'
            />
          </Col>
          <Col lg={4}>
              <StatsCard
                isSelected={page === 'Ramicash'}
                bigIcon={
                  <i
                    className='pe-7s-close-circle'
                    style={{ color: 'rgba(0,0,0,.7)' }}
                  />
                }
                statsText='Transações bloqueadas'
              statsValue={blockedTransactions?.length || 0}
                statsIcon={<i className='fa fa-info-circle' />}
              statsIconText={`${roundToTwo((blockedTransactions?.length / (pixPayments.data?.length + blockedTransactions?.length)) * 100) || 0}% das tentativas de transações bloqueadas`}
              />
          </Col>
          
        </Row>

        <Card>
          <Grid fluid>
            <Row style={{padding: '1rem 2rem'}}>
              <Col md={11}>
                <h5>Últimas Transações</h5>
              </Col>
              <Col md={1}>
                <Button 
                  color='primary'
                  onClick={handleClick}
                  startIcon={<FilterList />}
                  >
                  FILTRAR
                </Button>
                <div ref={calendarRef} id="calendar-container"></div>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  container={document.body}
                >
                  <Typography style={{ padding: '2rem 2rem' }}>
                    <form onSubmit={handleSubmit(filtrarDados)}>
                      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                        <FormGroup>
                          <ControlLabel>Período Inicial</ControlLabel>
                          <Controller
                            name="filtroDataInicio"
                            control={control}
                            render={({ field }) => (
                              <StyledDatePickerWrapper>
                                <DatePicker
                                  value={field.value}
                                  onChange={(val) => {
                                    const isoDate = val ? DateTime.fromISO(val).toISO() : null;
                                    field.onChange(isoDate);
                                  }}
                                  dateFormat="DD/MM/YYYY"
                                  className="form-control"
                                  style={{ zIndex: '999999 !important' }}
                                // calendarContainer={calendarRef.current}
                                />
                              </StyledDatePickerWrapper>

                            )}
                          />

                        </FormGroup>
                        <FormGroup>
                          <ControlLabel>Período Final</ControlLabel>
                          <Controller
                            name="filtroDataFim"
                            control={control}
                            render={({ field }) => (
                              <StyledDatePickerWrapper>
                                <DatePicker
                                  value={field.value}
                                  onChange={(val) => {
                                    const isoDate = val ? DateTime.fromISO(val).toISO() : null;
                                    field.onChange(isoDate);
                                  }}
                                  dateFormat="DD/MM/YYYY"
                                  className="form-control"
                                // container={document.body}
                                />

                              </StyledDatePickerWrapper>
                            )}
                          />
                        </FormGroup>

                        <Button
                          color='primary'
                          type='submit'
                        // startIcon={<FilterList />}
                        >
                          Pesquisar
                        </Button>

                      </div>
                    </form>
                  </Typography>
                </Popover>
              </Col>
            </Row>
        <Row>
          <Col md={12}>
                <CustomTable columns={columns} data={pixPayments?.data} hideCheckbox={true} selected={selected} setSelected={setSelected} />
          </Col>
        </Row>

          </Grid>

        </Card>
      </Grid>
    </div>
  );
}

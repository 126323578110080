import styled from 'styled-components';
import React, { useState } from 'react';
import Loading from '../../Components/Loading';
import { Grid, Row, Col, Badge } from 'react-bootstrap';
import MyAPI from '../../../../connections/Api.jsx';
import { StatsCard } from '../../../../components/StatsCard/StatsCard.jsx';

import Ramicash from './Ramicash.jsx';
import Notifications from './Notifications.jsx';
import DenseTable from '../../../../components/CustomTable/DenseTable.jsx';
import Card from '../../../../components/Card/Card.jsx';

export const Content = styled.div`
  cursor: pointer;
`;

export default function Dashboard() {
  const [data, setData] = useState(null);
  const [blockedTransactions, setBlockedTransactions] = useState(null);
  const [ramicashData, setRamicashData] = useState(null);

  const [refresh, setRefresh] = React.useState(false);
  const [page, setPage] = React.useState('Notifications');

  const columns = [
    { id: 'type', label: 'Tipo de Bloqueio', render: (row) => {
      return <Badge
                  variant="dot"
                  style={{ backgroundColor: '#dc3545', fontSize: '10px' }}
                >{getBlockedType(row.type)}</Badge>
    } },
    { id: 'amount', label: 'Valor', render: (row) => formatCurrency(row.amount) },
    { id: 'pix_code', label: 'Chave Pix', },
    { id: 'usuario.nome', label: 'Funcionário', render: (row) => row.usuario?.nome || '---' },
    { id: 'blocked_at', label: 'Data', },
  ];

  const formatCurrency = (value) => `R$ ${value.toFixed(2).replace('.', ',')}`;
  const getBlockedType = (value) => {
    if(value == 'LIMITE_MENSAL_EXCEDIDO') return 'Limite Mensal';
    if(value == 'LIMITE_DIARIO_EXCEDIDO') return 'Limite Diário'
    if(value == 'LIMITE_HORARIO_NOTURNO') return 'Limite Noturno'
  }

  React.useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await MyAPI.getInformations();
        const transactions = await MyAPI.getBlockedPixPayments();
        setBlockedTransactions(transactions.data)
        setData(data);

        const ramicash = await MyAPI.getRamicashList();
        
        setRamicashData(ramicash.data);
      } catch (e) {
        console.log(e);
      }
    }
    fetchData();
  }, [refresh]);

  if (data === null) return <Loading Height='950px' />;

  return (
    <div className='content'>
      <Grid fluid>
        <Row>
          <Col lg={4}>
            <Content onClick={() => setPage('Notifications')}>
              <StatsCard
                isSelected={page === 'Notifications'}
                bigIcon={
                  <i
                    className='pe-7s-paper-plane'
                    style={{ color: 'rgba(0,0,0,.7)' }}
                  />
                }
                statsText='Notificações Enviadas'
                statsValue={data?.notifications || 0}
                statsIcon={<i className='fa fa-info-circle' />}
                statsIconText='Total de mensagens enviadas'
              />
            </Content>
          </Col>
          <Col lg={4}>
            <Content onClick={() => setPage('Ramicash')}>
              <StatsCard
                isSelected={page === 'Ramicash'}
                bigIcon={
                  <i
                    className='pe-7s-cash'
                    style={{ color: 'rgba(0,0,0,.7)' }}
                  />
                }
                statsText='Novas Solicitações Ramicash'
                statsValue={ramicashData?.lastRequests.length || 0}
                statsIcon={<i className='fa fa-info-circle' />}
                statsIconText={`Total de pedidos ${ramicashData?.ramicashCount || 0}`}
              />
            </Content>
          </Col>
          <Col lg={4}>
            <StatsCard
              bigIcon={
                <i
                  className='pe-7s-users'
                  style={{ color: 'rgba(0,0,0,.7)' }}
                />
              }
              statsText='Usuários Online'
              statsValue={data?.allUsers || 0}
              statsIcon={<i className='fa fa-info-circle' />}
              statsIconText='Total de usuários ativos'
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            {
              {
                Notifications: (
                  <Row>
                    <Col md={6}>
                  <Notifications
                    refresh={() => setRefresh(!refresh)}
                    data={data}
                  />
                    </Col>
                    <Col md={6}>
                      <Card
                        title={
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <h5>TRANSAÇÕES BLOQUEADAS</h5>
                          </div>
                        }
                        content={
                          <div align='left' style={{ margin: 20 }}>
                            {
                              blockedTransactions.length > 0 ? (
                                <DenseTable columns={columns} data={blockedTransactions} truncateColumns={true} />
                              ) : (
                                <p>Nenhuma transação bloqueada até o momento</p>
                              )
                            }
                            {/* <DenseTable columns={columns} data={data.lastNotifications} truncateColumns={true} /> */}
                          </div>
                        }
                      />
                    </Col>
                  </Row>
                ),
                Ramicash: (
                  <Ramicash refresh={() => setRefresh(!refresh)} data={ramicashData} />
                ),
              }[page]
            }
          </Col>
        </Row>
      </Grid>
    </div>
  );
}

import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TableSortLabel,
    TextField,
    Tooltip
} from '@material-ui/core';

const useStyles = makeStyles({
    table: {
        width: '100%',
        tableLayout: 'fixed', // Evita que a tabela se expanda além do necessário
    },
    tableContainer: {
        maxWidth: '100%', // Garante que a tabela se ajuste ao container sem causar scroll
        overflowX: 'auto',
    },
    truncatedCell: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        padding: '6px 10px', // Reduz espaço interno da célula
        fontSize: '0.875rem', // Diminui um pouco a fonte para maior densidade
    },
    tooltipText: {
        display: 'block',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    customTooltip: {
        fontSize: '14px',
        backgroundColor: '#333',
        color: '#fff',
    },
});

export default function DenseTable({ columns, data, selected, setSelected, truncateColumns = false }) {
    const classes = useStyles();
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState(columns[0]?.id || "");
    const [filters, setFilters] = useState({});
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleFilterChange = (column, value) => {
        setFilters({ ...filters, [column]: value });
    };

    const getNestedValue = (obj, path) => {
        return path.split('.').reduce((acc, part) => acc && acc[part], obj);
    };

    const filteredData = data.filter((row) =>
        Object.keys(filters).every((key) => {
            const value = getNestedValue(row, key);
            return !filters[key] || (value && String(value).toLowerCase().includes(filters[key].toLowerCase()));
        })
    );

    const sortedData = [...filteredData].sort((a, b) =>
        order === "asc" ? (a[orderBy] > b[orderBy] ? 1 : -1) : a[orderBy] < b[orderBy] ? 1 : -1
    );

    return (
        <TableContainer component={Paper} className={classes.tableContainer}>
            <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        {columns.map((column) => (
                            <TableCell align="left" key={column.id} className={classes.truncatedCell}>
                                <TableSortLabel
                                    active={orderBy === column.id}
                                    direction={orderBy === column.id ? order : "asc"}
                                    onClick={() => handleRequestSort(column.id)}
                                >
                                    {column.label}
                                </TableSortLabel>
                                {column.filterable && (
                                    <TextField
                                        placeholder={column.label}
                                        onChange={(e) => handleFilterChange(column.id, e.target.value)}
                                        fullWidth
                                        size="small"
                                    />
                                )}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                        <TableRow key={row._id} hover>
                            {columns.map((column) => {
                                const cellValue = column.render ? column.render(row) : row[column.id];

                                return (
                                    <TableCell key={column.id} className={truncateColumns ? classes.truncatedCell : ""}>
                                        {truncateColumns ? (
                                            <Tooltip
                                                title={cellValue}
                                                arrow
                                                placement="bottom-start"
                                                classes={{ tooltip: classes.customTooltip }}
                                                PopperProps={{
                                                    modifiers: {
                                                        preventOverflow: {
                                                            enabled: true,
                                                            boundariesElement: 'window',
                                                        },
                                                    },
                                                }}
                                            >
                                                <span className={classes.tooltipText}>{cellValue}</span>
                                            </Tooltip>
                                        ) : (
                                            cellValue
                                        )}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import AdminNavbarLinks from "../Navbars/AdminNavbarLinks.jsx";

import logo from "../../assets/img/logo_ramicard.png";

import api from "../../services/api.jsx";
import { getToken } from "../../services/autenticacoes.jsx";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
    };    
  }

  async updateUserToken() {
    try {
      const resposta = await api.get("/me", {
        headers: { Authorization: `Bearer ${getToken()}`, acess: 0 },
      });
      if (! resposta.data.error) {
        this.setState({userInfo: resposta.data});
      }
    } catch (e) {
      
    }
  }

  normalizePath (path) { 
    return path.replace(/\/+$/, '');
  }

  activeRoute(routeName) {
    return this.normalizePath(this.props.location.pathname) === this.normalizePath(routeName) ? "active" : "";
  }

  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }

  componentDidMount() {
    this.updateDimensions();
    this.updateUserToken();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  render() {
    const sidebarBackground = {
      backgroundImage: "url(" + this.props.image + ")",
    };
    return (
      <div
        id="sidebar"
        className="sidebar"
        data-color={this.props.color}
        data-image={this.props.image}
      >
        {this.props.hasImage ? (
          <div className="sidebar-background" style={sidebarBackground} />
        ) : null}
        <div className="logo">
          <a href="/administrativo" className="simple-text logo-mini">
            <div className="logo-img">
              <img
                src={logo}
                alt="Rufus_Ramicard"
                style={{ width: "45px", marginTop: "8px" }}
              />
            </div>
          </a>
          <a
            href="/administrativo"
            className="simple-text logo-normal"
            style={{ fontSize: "22px", display: "flex", fontFamily: "Roboto" }}
          >
            Ramicard
          </a>
          <div
            style={{ fontSize: "16px", display: "flex", fontFamily: "Roboto" }}
          >
            { this.state.userInfo ? `${this.state.userInfo.nome}  ${this.state.userInfo.grupo}` : 'Administrador' }
          </div>
        </div>
        <div className="sidebar-wrapper">
          <ul className="nav">
            {this.state.width <= 991 ? <AdminNavbarLinks /> : null}
            {this.props.routes.map((prop, key) => {
              if (!prop.redirect)
                return (
                  <li
                    className={
                      prop.upgrade
                        ? "active-pro"
                        : this.activeRoute(prop.layout + prop.path)
                    }
                    key={key}
                  >
                    {prop.path === '/novidades' ?
                      <NavLink
                        to={prop.layout + prop.path}
                        className="nav-link"
                        activeClassName="active"
                      >
                        {<>
                          <i className={prop.icon} />
                          <p style={{ textTransform: 'capitalize' }}>{prop.name}</p>
                        </>}
                      </NavLink> :
                      <NavLink
                        to={prop.layout + prop.path}
                        className="nav-link"
                        activeClassName="active"
                      >
                        {<>
                          <i className={prop.icon} />
                          <p>{prop.name}</p>
                        </>}
                      </NavLink>
                    }
                  </li>
                );
              return null;
            })}
          </ul>
        </div>
      </div>
    );
  }
}

export default Sidebar;

import api from '../../../services/api.jsx';
import Card from '../../../components/Card/Card.jsx';
import React, { useState, useEffect } from 'react';
import { getToken } from '../../../services/autenticacoes.jsx';
import Capitalize from 'capitalize-pt-br';
import MyModal from '../../../components/Modal/ModalCredenciado.jsx';
import {
  Grid,
  Row,
  Col,
  Table,
  Tooltip,
  OverlayTrigger,
  FormControl,
} from 'react-bootstrap';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import MetodoPagamento from '../Components/Payment.jsx';
import ModalConfirmacao from '../Components/Confirmation.jsx';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const schema = yup.object().shape({
  valor: yup
    .string()
    .required('Valor é obrigatório'),
  observacao: yup.string(),
});

export default function Vendas() {
  const [data, setData] = useState([]);
  const [info, setInfo] = useState({});
  const [vendas, setVendas] = useState([]);
  const [error, setError] = useState(false);
  const [visivel, setVisivel] = useState(false);
  const [metodo, setMetodo] = useState(false);
  const [selecionado, setSelecionado] = useState(null);
  const [canais, setCanais] = useState(null);
  const [faixas, setFaixas] = useState(null);

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      valor: '',
      observacao: '',
    },
  });

  useEffect(() => {
    async function buscarDados() {
      try {
        const response = await api.get('/credenciado', {
          headers: { Authorization: `Bearer ${getToken()}`, acess: 3 },
        });
        setData(response.data);
        
        setVendas(response.data[0].canais_ativos);

        const canaisRes = await api.get('/canais', {
          headers: { Authorization: `Bearer ${getToken()}`, acess: 3 },
        });
        setCanais(canaisRes.data);

        const layersRes = await api.get('/layers', {
          headers: { Authorization: `Bearer ${getToken()}`, acess: 3 },
        });
        setFaixas(layersRes.data);
      } catch (error) {
        alert(error);
        setError(true);
      }
    }
    buscarDados();
  }, []);

  const formatCurrency = (value) => {
    const numericValue = value.replace(/\D/g, '');
    const floatValue = (Number(numericValue) / 100).toFixed(2);
    return `R$ ${floatValue.replace('.', ',')}`;
  };

  const handleValorChange = (value) => {
    setValue('valor', formatCurrency(value));
  };

  async function ConfirmacaoFinal({ valor, observacao }) {
    const valorLimpo = valor.replace(/[^0-9,]/g, '').replace(',', '.');
    const canalSelecionado = vendas[selecionado];

    const response = await api.post(
      `/credenciado/${data[0]._id}/gerar-cobranca/`,
      {
        valor: valorLimpo,
        canal: canalSelecionado.canal,
        max_parcelas: canalSelecionado.parcelas_max,
        taxa_ramicard: canalSelecionado.taxa || 0,
        observacao,
      },
      { headers: { Authorization: `Bearer ${getToken()}`, acess: 3 } }
    );

    setInfo({
      informacoes: data[0]._id,
      codigo_transfere: String(response.data._id).split('').reverse().join(''),
      canal: canalSelecionado,
      credenciado_data: {
        razao: data[0].razao_social,
        fantasia: data[0].nome_fantasia,
      },
    });
    setMetodo(true);
  }

  return (
    <div className='content'>
      <Grid fluid>
        <Row>
          <MyModal show={error} onHide={() => setError(false)} />
          <MetodoPagamento
            {...info}
            show={metodo}
            onHide={() => setMetodo(false)}
            onQrcode={() => setVisivel(true)}
            onCleared={() => reset()}
          />
          <ModalConfirmacao
            {...info}
            show={visivel}
            onHide={() => setVisivel(false)}
            onCleared={() => reset()}
          />
          <Col md={12}>
            <Card
              title={data.length > 0 ? 'Vendas' : ''}
              ctTableFullWidth
              ctTableResponsive
              content={
                <form onSubmit={handleSubmit(ConfirmacaoFinal)}>
                      <Table striped hover>
                        <thead>
                          <tr>
                        <th>Canal</th>
                        <th>Valor de Venda</th>
                        <th>Observação</th>
                        <th>Confirmar</th>
                          </tr>
                        </thead>
                        <tbody>
                      {vendas.map((prop, key) => (
                              <tr key={key}>
                                <td align='center'>{Capitalize(prop.canal)}</td>
                                <td align='center'>
                            <Controller
                              name='valor'
                              control={control}
                              render={({ field }) => (
                                    <FormControl
                                  {...field}
                                      type='text'
                                  placeholder='R$ 00,00'
                                  onFocus={() => setSelecionado(key)}
                                  onChange={(e) => handleValorChange(e.target.value)}
                                    />
                              )}
                            />
                            <p>{errors.valor?.message}</p>
                                </td>
                                <td align='center'>
                            <Controller
                              name='observacao'
                              control={control}
                              render={({ field }) => <FormControl {...field} type='text' maxLength={50} placeholder='Observações' />}
                                    />
                            <p>{errors.observacao?.message}</p>
                                </td>
                                <td align='center'>
                            <Button type='submit' disabled={selecionado !== key}>Confirmar Venda</Button>
                                </td>
                              </tr>
                      ))}
                        </tbody>
                      </Table>
                </form>
              }
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
}

import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import capitalize from 'capitalize-pt-br';
import PushModal from '../../Components/PushModal';
import Card from '../../../../components/Card/Card.jsx';
// import Button from '../../../../components/CustomButton/CustomButton.jsx';
import DenseTable from '../../../../components/CustomTable/DenseTable.jsx';
import { Button } from '@material-ui/core';

export default function Notifications({ data }) {
  const columns = [
    { id: 'title', label: 'Tìtulo' },
    { id: 'message', label: 'Mensage' },
    { id: 'tags', label: 'Tag', render: (row) => getTags(row) },
    {
      id: 'big_picture', label: 'Imagem Anexada', render: (row) => getLink(row)
    },
  ];

    const getLink = ({ big_picture }) => {
      return big_picture ? (
        <a href={big_picture} target='_blank' rel='noopener noreferrer'>
          Visualizar Imagem
        </a>
      ) : (
        'Nenhuma imagem anexada'
      );
    };

  const getTags = (notification) => {
    return capitalize(
              notification?.tags.map((item) => item.value).join(', ')
    ) || 'Envio realizado a todos os usuários'
  }

  const [showHide, setShowHide] = useState(false);

  return (
    <>
      <PushModal show={showHide} closeModal={() => setShowHide(false)} />
      <Card
        title={
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
            <h5>ENVIOS RECENTES</h5>
            <Button
              style={{
                backgroundColor: '#008000',
                color: 'white',
              }}
              onClick={() => setShowHide(true)}
            >
              <i className='fa fa-envelope' aria-hidden='true' /> Nova Mensagem
            </Button>
          </div>
        }
        content={
          <div align='left' style={{ margin: 20 }}>
            <DenseTable columns={columns} data={data.lastNotifications} truncateColumns={true} />
          </div>
        }
      />
    </>
  );
}

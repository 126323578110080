import Api from "../connections/Api";

export default async function findMaxInstallmentsByValue (valor) {
    const layer = await Api.getStandardLayer()
    console.log(layer)

    const faixaParcela = layer.data.faixas.find(
        (f) =>
            valor >= f.valorMinimo &&
            ((f.valorMaximo === 0) || (valor <= f.valorMaximo))
    );

    console.log(faixaParcela)

    return faixaParcela.parcelas
}
import React, { useState, useEffect } from 'react';
import Card from '../../../../components/Card/Card.jsx';
import Button from '../../../../components/CustomButton/CustomButton.jsx';
import api from '../../../../services/api.jsx';
import down from '../../../../assets/img/down-arrow.png';
import { getToken } from '../../../../services/autenticacoes.jsx';
import MyModal from '../../../../components/Modal/MyModal.jsx';
import ModalConfirmacao from '../../../../components/Modal/ModalConfirmacao.jsx';

import {
  Col,
  ControlLabel,
  FormControl,
  FormGroup,
  Grid,
  Image,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from 'react-bootstrap';
import CustomTable from '../../../../components/CustomTable/CustomTable.jsx';
import CorrecaoForm from './CorrecaoForm.jsx';

export default function Correcao() {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [ajuste, setAjuste] = useState(0);
  const [error, setError] = useState(false);
  const [visivel, setVisivel] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [selected, setSelected] = useState([]);
  const columns = [
    {
      id: 'status',
      label: 'Status',
      align: 'center',
      filterable: true,
      render: (row) => row.ativo ? 'ATIVO' : 'SUSPENSO',
    },
    {
      id: 'razao_social',
      label: 'Razão Social',
      align: 'center',
      filterable: true,
      render: (row) => getHighlightedText(row.razao_social),
    },
    {
      id: 'nome_fantasia',
      label: 'Nome Fantasia',
      align: 'center',
      filterable: true,
      render: (row) => getHighlightedText(row.nome_fantasia),
    },
    {
      id: 'contagem_funcionarios',
      label: 'Func.',
      align: 'center',
      filterable: true,
      render: (row) => row.contagem_funcionarios,
    },
    {
      id: 'limite_medio',
      label: 'Limite Md.',
      align: 'center',
      filterable: true,
      render: (row) => `R$ ${(row.soma_limites / row.contagem_funcionarios || 0).toFixed(2)}`,
    },
    {
      id: 'contagem_compras',
      label: 'Compras',
      align: 'center',
      filterable: true,
      render: (row) => row.contagem_compras,
    },
    {
      id: 'valor_medio',
      label: 'Valor Md.',
      align: 'center',
      filterable: true,
      render: (row) => `R$ ${(row.soma_compras / row.contagem_compras || 0).toFixed(2)}`,
    },
    // {
    //   id: "acoes",
    //   label: "",
    //   render: (row) => {
    //     if (!row) return null; // Evita erro se row estiver undefined

    //     return (
    //       <div className="actions-center" style={{ padding: '0 !important', margin: '0 !important' }}>
    //         <OverlayTrigger
    //           placement="top"
    //           overlay={<Tooltip id={`edit_tooltip_${row._id}`}>Editar</Tooltip>} // Evita ID duplicado
    //         >
    //           <Button
    //             bsStyle="info"
    //             simple={true}
    //             type="button"
    //             bsSize="sm"
    //             onClick={() => console.log("on edit", row)}
    //           >
    //             <div style={{ fontSize: 20 }}>
    //               <i className="fa fa-edit" />
    //             </div>
    //           </Button>
    //         </OverlayTrigger>
    //       </div>
    //     );
    //   },
    // }

    // {
    //     id: 'ajuste',
    //     label: 'Ajuste(%)',
    //     align: 'center',
    //     render: (row, handleChange) => (
    //         <FormControl
    //           type='number'
    //           bsClass='form-control'
    //           placeholder='Exemplo: 3.5'
    //           defaultValue={row.ajuste}
    //           onChange={(event) => { handleChange(event.target.value, row) }}
    //           style={{ maxWidth: '70px', maxHeight: '25px', fontSize: '12px', textAlign: 'right' }}
    //         />
    //     ),
    // },
  ];


  useEffect(() => {
    async function listarEmpresas() {
      try {
        const resposta = await api.get('/administrativo/empresas', {
          headers: { Authorization: `Bearer ${getToken()}`, acess: 0 },
        });
        if (resposta.data.error) {

        } else {
          let empresas = resposta.data.map((item) => {
            return {...item, ajuste }
          });
          setData(empresas);
          setFilteredData([]);
        }
      } catch (error) {
        setError(true);
      }
    }
    listarEmpresas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function refreshScreen() {
      setRefresh(false);
    }
    refreshScreen();
  }, [refresh]);

  const handleSubmit = async (data) => {
    console.log(selected, data)
    const { result } = await api.post(
      `/administrativo/empresas/ajustar-limites`, { empresas: selected, data },
      { headers: { Authorization: `Bearer ${getToken()}`, acess: 0 } }
    );
  } 

  const ajustarLimite = () => {
    let empresas = data.map((item) => {
      return {...item, ajuste}
    });
    console.log(empresas)
    setData(empresas);
    setRefresh(true);
  }
  
  const setarLimiteEmpresa = (ajuste, key) => {
    let empresas = data;
    data[key].ajuste = ajuste;
    setData(empresas);
    setRefresh(true);
  }

  const filtrar = (coluna, valor) => {
    setSearchTerm(valor);
    setFilteredData(valor.trim() !== ''? data.filter((item) => item[coluna].indexOf(valor) >= 0 ) : data);
    setRefresh();
  }

  const getHighlightedText = (text) => {    
    const parts = text.split(new RegExp(`(${searchTerm})`, 'gi'));
    return <span> { parts.map((part, i) => 
        <span key={i} style={part.toLowerCase() === searchTerm.toLowerCase() ? { fontWeight: 'bold', backgroundColor: 'yellow' } : {} }>
            { part }
        </span>)
    } </span>;
  }

  async function baixarListaConferencia() {
    try {
      const { data } = await api.get(
        `/administrativo/empresas/conferencia-limites`,
        { responseType: 'blob', headers: { Authorization: `Bearer ${getToken()}`, acess: 0 } }
      );

      if (data.error) {
        alert(data.message);
      } else {
        const blob = new Blob([data], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.href = url;
        link.download = 'conferencia-limites.csv';
        link.click();

        window.URL.revokeObjectURL(url);
      }

    } catch (error) {
      console.log(error);
    }
  }

  async function ajustarLimites() {
    const body = data.map((empresa) => {
      return {
        _id: empresa._id,
        ajuste: empresa.ajuste ?? 0
      }
    })
    const { result } = await api.post(
      `/administrativo/empresas/ajustar-limites`, {empresas: body},
      { headers: { Authorization: `Bearer ${getToken()}`, acess: 0 } }
    );

    if (result.error) {
      alert(`Erro: ${result.message}`);
    } else {
      alert(`${result.message}, exporte a listagem para conferência`);
    }
  }

  const atualizarResult = (result) => {
    setVisivel(false);
    if ( result ) {
      ajustarLimites();
    }
  }

  return (
    <div className='content'>
      <Grid fluid>
        <MyModal show={error} onHide={() => setError(false)} />
        <ModalConfirmacao
          show={visivel}
          onHide={() => setVisivel(false)}
          title="Corrigir Limites"
          pergunta="Confirma definição do ajuste dos limites para todos os funcionários?"
          confirma="Confirmar"
          cancela="Cancelar"
          onConfirma={() => { atualizarResult(true) }}
          onCancela={() => { atualizarResult(false) }}
        />
        <Row>
          <Col md={12}>
            <Card              
              content={
                <div>
                  <Col>
                    <span style={{ fontSize: '1.75rem', marginLeft: '4px', textTransform: 'uppercase', }}>Ajustar Limite</span>
                    <CorrecaoForm selectedCompanies={selected} onSubmit={handleSubmit}  />
                    {/* <div>
                      <Button
                          onClick={() => { baixarListaConferencia() }}
                          type='submit'
                          bsStyle='success'
                          variant='contained'
                          style={{
                            marginLeft: '5px',
                            marginBottom: '10px',
                            background: 'rgba(35,142,35, .8)',
                            border: 0,
                            borderRadius: 3,
                            boxShadow: '0 1px 1px 1px rgba(0, 0, 0, .2)',
                            color: 'white',
                            fontSize: '14px',
                          }}
                        >
                        Arquivo para Conferência  
                      </Button>
                      <Button
                          onClick={() => { setVisivel(true) }}
                          type='submit'
                          bsStyle='success'
                          variant='contained'
                          style={{
                            marginLeft: '5px',
                            marginBottom: '10px',
                            background: 'rgba(35,142,35, .8)',
                            border: 0,
                            borderRadius: 3,
                            boxShadow: '0 1px 1px 1px rgba(0, 0, 0, .2)',
                            color: 'white',
                            fontSize: '14px',
                          }}
                        >
                        Definir novo Limite  
                      </Button>
                    </div> */}
                  </Col>
                  <hr/>
                  <Row>
                    <CustomTable columns={columns} data={filteredData.length > 0 ? filteredData : data} onUpdate={() => console.log("updating...")} onSave={() => console.log("saving...")} selected={selected} setSelected={setSelected} />
                  </Row>
                </div>
              }
              legend={
                <div>
                </div>
              }
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
}

import React from 'react';

import Guias from './Guides';
import Empresas from './Companies';
import Analitico from './Analytical';
import Credenciados from './Accredited';
import Previsoes from './Predictions/index.jsx';
import Ramicash from './Ramicash/index.jsx';
import PIX from './PIX/index.jsx';
import { Button } from 'primereact/button';
import { Grid, Row, Col } from 'react-bootstrap';
import Card from '../../../../components/Card/Card.jsx';
import icon_company from '../../../../assets/img/icon_company.png';
import icon_graph_buys from '../../../../assets/img/icon_graph_buys.png';
import icon_graph_pizza from '../../../../assets/img/icon_graph_pizza.png';
import icon_company_preview from '../../../../assets/img/icon_company_preview.png';
import icon_company_recived from '../../../../assets/img/icon_company_recived.png';
import icon_pix from '../../../../assets/img/pix.png';
import PixReport from './PIX/PixReport.jsx';

export default function Clients() {
  const [page, setPage] = React.useState('Menu');

  return (
    <div className='main-content'>
      <Grid fluid>
        <Row>
          {
            {
              Menu: (
                <div
                  className='content'
                  style={{ marginTop: 25, minHeight: '825px' }}
                >
                  <Grid fluid>
                    <Row>
                      <Col md={12}>
                        <Card
                          title={
                            <>
                              <p style={{ fontSize: 20 }}>
                                Relatórios de operacionais
                              </p>
                              <p
                                style={{
                                  fontSize: 15,
                                  color: '#A9A9A9',
                                  marginTop: -10,
                                }}
                              >
                                Os dados são criados a partir das informações
                                dos comprovantes de vendas.
                              </p>
                            </>
                          }
                          content={
                            <div>
                              <div>
                                <Button
                                  onClick={() => setPage('Guias')}
                                  label=' '
                                  className='p-button-success'
                                  style={{
                                    marginRight: 20,
                                    marginLeft: 20,
                                    borderRadius: 10,
                                    marginBottom: 10,
                                  }}
                                >
                                  <div style={{ margin: 30 }}>
                                    <img
                                      src={icon_graph_buys}
                                      alt='Rufus_Ramicard'
                                      style={{
                                        width: '60px',
                                        marginBottom: 20,
                                      }}
                                    />
                                    <p style={{ width: 200 }}>Guias Emitidas</p>
                                  </div>
                                </Button>

                                <Button
                                  onClick={() => setPage('Credenciados')}
                                  label=' '
                                  className='p-button-success'
                                  style={{
                                    marginRight: 20,
                                    marginLeft: 20,
                                    borderRadius: 10,
                                    marginBottom: 10,
                                  }}
                                >
                                  <div style={{ margin: 30 }}>
                                    <img
                                      src={icon_company}
                                      alt='Rufus_Ramicard'
                                      style={{
                                        width: '60px',
                                        marginBottom: 20,
                                      }}
                                    />
                                    <p style={{ width: 200 }}>
                                      Pagamento de Prestadores
                                    </p>
                                  </div>
                                </Button>

                                <Button
                                  onClick={() => setPage('Empresas')}
                                  label=' '
                                  className='p-button-success'
                                  style={{
                                    marginRight: 20,
                                    marginLeft: 20,
                                    borderRadius: 10,
                                    marginBottom: 10,
                                  }}
                                >
                                  <div style={{ margin: 30 }}>
                                    <img
                                      src={icon_company_recived}
                                      alt='Rufus_Ramicard'
                                      style={{
                                        width: '60px',
                                        marginBottom: 20,
                                      }}
                                    />
                                    <p style={{ width: 200 }}>
                                      Recebimentos Empresariais
                                    </p>
                                  </div>
                                </Button>

                                <Button
                                  disabled={false}
                                  onClick={() => setPage('Analitico')}
                                  label=' '
                                  className='p-button-success'
                                  style={{
                                    marginRight: 20,
                                    marginLeft: 20,
                                    borderRadius: 10,
                                    marginBottom: 10,
                                  }}
                                >
                                  <div style={{ margin: 30 }}>
                                    <img
                                      src={icon_graph_pizza}
                                      alt='Rufus_Ramicard'
                                      style={{
                                        width: '60px',
                                        marginBottom: 20,
                                      }}
                                    />
                                    <p style={{ width: 200 }}>
                                      Recebimentos Análitico
                                    </p>
                                  </div>
                                </Button>
                                
                                <Button
                                  disabled={false}
                                  onClick={() => setPage('PixReport')}
                                  label=' '
                                  className='p-button-success'
                                  style={{
                                    marginRight: 20,
                                    marginLeft: 20,
                                    borderRadius: 10,
                                    marginBottom: 10,
                                  }}
                                >
                                  <div style={{ margin: 30 }}>
                                    <img
                                      src={icon_graph_pizza}
                                      alt='Rufus_Ramicard'
                                      style={{
                                        width: '60px',
                                        marginBottom: 20,
                                      }}
                                    />
                                    <p style={{ width: 200 }}>
                                      PIX Analítico
                                    </p>
                                  </div>
                                </Button>
                              </div>
                            </div>
                          }
                        />

                        <Card
                          title={
                            <>
                              <p style={{ fontSize: 20 }}>
                                Relatórios administrativos
                              </p>
                              <p
                                style={{
                                  fontSize: 15,
                                  color: '#A9A9A9',
                                  marginTop: -10,
                                }}
                              >
                                Os dados são referêntes a prospecção de períodos
                                futuros.
                              </p>
                            </>
                          }
                          content={
                            <div>
                              <div>
                                <Button
                                  onClick={() => setPage('Previsoes')}
                                  label=' '
                                  className='p-button-success'
                                  style={{
                                    marginLeft: 20,
                                    marginRight: 20,
                                    borderRadius: 10,
                                    marginBottom: 10,
                                  }}
                                >
                                  <div style={{ margin: 30 }}>
                                    <img
                                      src={icon_company_preview}
                                      alt='Rufus_Ramicard'
                                      style={{
                                        width: '60px',
                                        marginBottom: 20,
                                      }}
                                    />
                                    <p style={{ width: 200 }}>Previsões</p>
                                  </div>
                                </Button>

                                <Button
                                  onClick={() => setPage('Ramicash')}
                                  label=' '
                                  className='p-button-success'
                                  style={{
                                    marginLeft: 20,
                                    marginRight: 20,
                                    borderRadius: 10,
                                    marginBottom: 10,
                                  }}
                                >
                                  <div style={{ margin: 30 }}>
                                    <img
                                      src={icon_company_preview}
                                      alt='Rufus_Ramicard'
                                      style={{
                                        width: '60px',
                                        marginBottom: 20,
                                      }}
                                    />
                                    <p style={{ width: 200 }}>Ramicash</p>
                                  </div>
                                </Button>

                                <Button
                                  onClick={() => setPage('PIX')}
                                  label=' '
                                  className='p-button-success'
                                  style={{
                                    marginLeft: 20,
                                    marginRight: 20,
                                    borderRadius: 10,
                                    marginBottom: 10,
                                  }}
                                >
                                  <div style={{ margin: 30 }}>
                                    <img
                                      src={icon_pix}
                                      alt='Rufus_Ramicard'
                                      style={{
                                        width: '60px',
                                        marginBottom: 20,
                                      }}
                                    />
                                    <p style={{ width: 200 }}>PIX</p>
                                  </div>
                                </Button>
                              </div>
                            </div>
                          }
                        />
                      </Col>
                    </Row>
                  </Grid>
                </div>
              ),
              Guias: <Guias goTo={(page) => setPage(page)} />,
              Credenciados: <Credenciados goTo={(page) => setPage(page)} />,
              Empresas: <Empresas goTo={(page) => setPage(page)} />,
              Analitico: <Analitico goTo={(page) => setPage(page)} />,
              Previsoes: <Previsoes goTo={(page) => setPage(page)} />,
              Ramicash: <Ramicash goTo={(page) => setPage(page)} />,
              PIX: <PIX goTo={(page) => setPage(page)} />,
              PixReport: <PixReport goTo={(page) => setPage(page)} />,
            }[page]
          }
        </Row>
      </Grid>
    </div>
  );
}
